import { CognitoIdentityProviderClient, ChangePasswordCommand, DeleteWebAuthnCredentialCommand, ListWebAuthnCredentialsCommand } from "@aws-sdk/client-cognito-identity-provider";

const client = new CognitoIdentityProviderClient({ 
    region: "eu-west-2"
  });

export async function changePassword(accessToken, oldPassword, newPassword) {
    const command = new ChangePasswordCommand({
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword,
      AccessToken: accessToken
    });
  
    try {
      const response = await client.send(command);
      return response;
    } catch (error) {
      throw new Error(`Failed to change password: ${error.message}`);
    }
  }
  
export async function deleteWebAuthnCredential(accessToken, credentialId) {
    try {
      const command = new DeleteWebAuthnCredentialCommand({
        AccessToken: accessToken,
        CredentialId: credentialId
      });
      
      await client.send(command);
    } catch (error) {
      throw new Error(`Failed to delete WebAuthn credential: ${error.message}`);
    }
  }
  
export async function listWebAuthnCredentials(accessToken) {
    try {
      const command = new ListWebAuthnCredentialsCommand({
        AccessToken: accessToken
      });
      
      const response = await client.send(command);
      return response.Credentials || [];
    } catch (error) {
      throw new Error(`Failed to list WebAuthn credentials: ${error.message}`);
    }
  }