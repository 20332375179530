import { Card, Text, Button, Stack } from '@mantine/core';
import { useState, useEffect } from 'react';
import { listWebAuthnCredentials, deleteWebAuthnCredential } from './util/cognito'

export function WebAuthnCredentials({ accessToken }) {
  const [credentials, setCredentials] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (accessToken) {
      listWebAuthnCredentials(accessToken)
        .then(setCredentials)
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [accessToken]);

  const handleDelete = async (credentialId) => {
    try {
      await deleteWebAuthnCredential(accessToken, credentialId);
      // Update the list by removing the deleted credential
      setCredentials(credentials.filter(cred => cred.CredentialId !== credentialId));
    } catch (error) {
      console.error('Failed to delete credential:', error);
    }
  };

  if (loading) {
    return <Text>Loading credentials...</Text>;
  }

  return (
    <Card shadow="sm" p="lg">
      <Text size="xl" weight={500} mb="md">WebAuthn Credentials</Text>
      {credentials.map((credential) => (
        <Card key={credential.CredentialId} mb="sm" withBorder>
          <Stack spacing="xs">
            <Text weight={500}>{credential.FriendlyCredentialName || 'Unnamed credential'}</Text>
            <Text size="sm" color="dimmed">
              Created: {new Date(credential.CreatedAt * 1000).toLocaleDateString()}
            </Text>
            <Text size="sm" color="dimmed">
              Authenticator Type: {credential.AuthenticatorAttachment}
            </Text>
            <Text size="sm" color="dimmed">
              Transport Methods: {credential.AuthenticatorTransports.join(', ')}
            </Text>
            <Text size="sm" color="dimmed">
              Relying Party: {credential.RelyingPartyId}
            </Text>
            <Button 
              color="red" 
              variant="light"
              onClick={() => handleDelete(credential.CredentialId)}
            >
              Delete Credential
            </Button>
          </Stack>
        </Card>
      ))}
    </Card>
  );
}

