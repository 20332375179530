const clientId = "6odvsgn9b1tiomru2mdidjd9c2"
const cognitoDomain = "https://cognito.auth.gillham-net.co.uk"
const authority = "https://cognito-idp.eu-west-2.amazonaws.com/eu-west-2_6Qmfbe3Da"

export const signOutRedirect = () => {
    const logoutUri = window.location.origin;
    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
  };

export const addPasskey = () => {
    const redirectUri = window.location.origin;
    window.location.href = `${cognitoDomain}/passkeys/add?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}`;
  };

const authConfig = {
    authority,
    client_id: clientId,
    redirect_uri: window.location.origin,
    scope: "openid email profile aws.cognito.signin.user.admin",
    loadUserInfo: true,
};

export default authConfig;