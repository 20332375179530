import { Modal, TextInput, Button, Group } from '@mantine/core';
import { useState } from 'react';
import { changePassword } from './util/cognito'

export function ChangePasswordModal({ opened, onClose, accessToken }) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      // TODO: Implement Cognito change password call
      await changePassword(accessToken, oldPassword, newPassword);
      onClose();
    } catch (error) {
      console.error('Failed to change password:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal opened={opened} onClose={onClose} title="Change Password">
      <form onSubmit={handleSubmit}>
        <TextInput
          required
          label="Current Password"
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          mb="md"
        />
        <TextInput
          required
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          mb="md"
        />
        <Group justify="flex-end">
          <Button type="submit" loading={loading}>
            Change Password
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
