import '@mantine/core/styles.css';
import { useAuth } from "react-oidc-context";
import { MantineProvider, AppShell, Button, Text, Group, Container } from '@mantine/core';
import { useState } from 'react';
import { addPasskey, signOutRedirect } from "./util/oidc-auth";
import { ChangePasswordModal } from './ChangePasswordModal';
import { WebAuthnCredentials } from './WebAuthnCredentials';

function App() {
  const auth = useAuth();
  const [changePasswordOpened, setChangePasswordOpened] = useState(false);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Encountering error... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    
    return (
      <MantineProvider>
        <AppShell>

          <Container size="md" py="xl">
            <Text>Account Management</Text>
          </Container>

          <Container size="md" py="xl">
            <Button onClick={addPasskey}>
              Add Passkey
            </Button>
            <Button onClick={() => setChangePasswordOpened(true)}>
              Change Password
            </Button>
            <Button onClick={() => auth.removeUser()} variant="light" color="red">
              Log out
            </Button>
          </Container>

          <Container size="md" py="xl">
            <Text mb="lg">Welcome, {auth.user?.profile.email}</Text>
            <WebAuthnCredentials accessToken={auth.user?.access_token} />
          </Container>

          <ChangePasswordModal
            opened={changePasswordOpened}
            onClose={() => setChangePasswordOpened(false)}
            accessToken={auth.user?.access_token}
          />
        </AppShell>
      </MantineProvider>
    );
  }

  return (
    <MantineProvider>
      <Container size="xs" py="xl">
        <Group justify="center">
          <Button onClick={() => auth.signinRedirect()}>Sign in</Button>
          <Button onClick={() => signOutRedirect()} variant="light">Sign out</Button>
        </Group>
      </Container>
    </MantineProvider>
  );
}


export default App;
